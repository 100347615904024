
import { gsap, ScrollTrigger } from "gsap/all";
import { splitChars } from './utils';

gsap.registerPlugin(ScrollTrigger);

const init = () => {
  
  let cardsTl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: '.cards-container',
      start: "bottom center",
      start: "top center",
      scrub: 1
    } 
  });
  cardsTl.from(`.cards-container .game-card`, { rotate: 0});

    const tl = gsap.timeline({ paused: true });

    tl.to(".img1", {scale: 1.1});

    let scrollTl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: '.section__hero',
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        } 
      });

      scrollTl
      /*
      
      .to(`.logo-cont svg`, { scale: 1.6, y: '-10%'}, 0)
      .to(`.img-1`, { x: '-100%', rotate: '-2', scale: 1.2}, 0)
      .to(`.img-8`, { x: '-150%', rotate: '-20', scale: 1.4}, 0)
      .to(`.img-12`, { x: '-150%', rotate: '-5', scale: 1.4}, 0)
      .to(`.img-10`, { x: '-150%', rotate: '-5', scale: 1.4}, 0)
      
      
      .to(`.img-2`, { x: '100%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-3`, { x: '200%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-5`, { x: '250%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-6`, { x: '100%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-7`, { x: '200%', rotate: '2', scale: 1.4}, 0)
      
      
      .to(`.img-4`, { y: '-150%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-9`, { y: '100%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-11`, { y: '100%', x: '50%', rotate: '5', scale: 1.4}, 0)
      */


      .to(`.logo-cont svg`, { scale: 1.6, y: '-10%'}, 0)
      .to(`.img-1`, { x: '-10%', rotate: '-2', scale: 1.2}, 0)
      .to(`.img-8`, { x: '-15%', rotate: '-5', scale: 1.4}, 0)
      .to(`.img-12`, { x: '-15%', rotate: '-5', scale: 1.4}, 0)
      .to(`.img-10`, { x: '-15%', rotate: '-5', scale: 1.4}, 0)
      
      
      .to(`.img-2`, { x: '10%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-3`, { x: '20%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-5`, { x: '25%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-6`, { x: '10%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-7`, { x: '20%', rotate: '2', scale: 1.4}, 0)
      
      
      .to(`.img-4`, { y: '-15%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-9`, { y: '10%', rotate: '2', scale: 1.4}, 0)
      .to(`.img-11`, { y: '10%', x: '50%', rotate: '5', scale: 1.4}, 0)
      .addLabel('startOut')

      //.to('.section__hero', { backgroundColor: '#F5F5F5'}, 'startOut')
      .to('.section__hero', { autoAlpha: 0}, 'startOut')

      //.to(`.img1, .img2, .img3, .img4, .img5, .img6, .img7`, { opacity: 0}, 'startOut')
      ;
}

export default { init };