import './style.css';

import scrollEvents from './js/scrollEvents.js';
import heroIn from './js/heroIn.js';

scrollEvents.init();
heroIn.init();

/*
import barba from '@barba/core';
import gsap from 'gsap';
import AOS from 'aos';
import Rellax from 'rellax';
import Ukiyo from "ukiyojs";
import sliders from './js/sliders';

import cursor from './js/cursor.js';
import textHacks from './js/textHacks';
import menu from './js/menuAni.js';
import accordion from './js/accordion.js';
import scrollEvents from './js/scrollEvents.js';
import homeAni from './js/homeAni.js';
import about from './js/about.js';
import loader from './js/loader.js';
import inAnimations from './js/inAnimations.js';
import inBigTitle from './js/inBigTitle.js';


let ukiyo = new Ukiyo("[data-ukiyo]");
let rellax = new Rellax('[data-rellax]', { speed: 1 });
cursor.init();
loader.set();

let lastScrollTop = 0;

const resetWebflow = (data) => {
    let parser = new DOMParser();
    let dom = parser.parseFromString(data.next.html, "text/html");
    let webflowPageId = $(dom).find("html").attr("data-wf-page");
    $("html").attr("data-wf-page", webflowPageId);
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
}

function onPageAfter(data, delay = false) {

    sliders.init();
    textHacks.init();
    scrollEvents.init();
    AOS.refresh();
    accordion.init();
    cursor.initHovers();
    about.init();
    cursor.endLoading();
    inAnimations.slideLeftWords();

    inBigTitle.play();

    if (data.next.namespace === 'home'){
        homeAni.init();
    }

    ukiyo = new Ukiyo("[data-ukiyo]");
    rellax = new Rellax('[data-rellax]', {speed: 1});

    // Set next link in products page
    const links = document.querySelectorAll('#projects-list a');
    if (links.length > 0) {
        const currentIndex = Array.from(links).findIndex(link => link.classList.contains('w--current'));
        const nextLink = links[currentIndex + 1] ? links[currentIndex + 1].getAttribute('href') : links[0].getAttribute('href');
        document.querySelector('.button-arrow--2').setAttribute('href', nextLink);
    }
    
    // Force textarea height
    if(document.querySelectorAll('textarea').length > 0){
        document.querySelectorAll('textarea').forEach((el) => {
            el.setAttribute('rows', '1');
        });
    }


   
    // Set current page
    if(data.next.namespace){
        document.querySelectorAll('[data-page]').forEach((el) => {
            if(el.getAttribute('data-page') == data.next.namespace){
                el.classList.add('w--current');
                el.ariaCurrent = 'page';
            }else{
                el.classList.remove('w--current');
                el.ariaCurrent = false;
            }
        });

        document.body.setAttribute('data-page', data.next.namespace);
    }

    // Se arrivo dai progetti rimetto lo scroll dov'era prima di arrivarci
    if (data.current) {
        if(data.next.namespace === 'home' && data.current.namespace == 'project'){
            window.scrollTo(0, lastScrollTop);
        }
    }
};

barba.hooks.before((data) => {

    cursor.initLoading();
});

barba.hooks.afterLeave((data) => {
    ukiyo.destroy();
    rellax.destroy();
});

// After all
barba.hooks.after((data) => {
    onPageAfter(data);
});

barba.hooks.beforeEnter((data) => {
    menu.selectCurrent(data.next.namespace);

    inBigTitle.set(data.next.container);

  let newClasses = data.next.container.classList;
  newClasses.remove('page-wrapper');
  newClasses = newClasses.toString();
    document.body.classList = newClasses;
});
   
window.onload = () => {

    let currentContainer = document.querySelector('[data-barba-namespace]');
    let currentPage = currentContainer.getAttribute('data-barba-namespace');

    onPageAfter({ next: { container: currentContainer, namespace: currentPage}}, 1.4);

    menu.init();

    AOS.init({
        offset: 50,
        once: true
    });

    loader.play(200);

};

// basic default transition (with no rules and minimal hooks)
barba.init({
    timeout: 5000,
    transitions: [{
        name: 'basic-transition',
        leave: function(data) {

          var done = this.async();
          var el = data.current.container;
          document.body.classList.add('inTransition');

          let leave_tl = gsap.timeline({
              onComplete: function() {

                  document.body.classList.add('inTransition-isOut');

                  el.remove();
                  done();
              }
          });

            if (el.querySelectorAll('.projects-category').length > 0) {
                leave_tl.to('.projects-category', { duration: 0.8, opacity: 0, y: '-=100px', stagger: 0.05, ease: "power4.in" });
            }


            leave_tl.to(el ,{
                duration: 1,
                opacity: 0,
                y: -100,
                ease: "power4.in"
            });
        },
        afterLeave: function(data){

        },
        enter: function(data) {
            
            var done = this.async();
            var el = data.next.container.querySelector('.main-wrapper');

            //el.style.opacity = 1;

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

            gsap.set(el, {
                y: 100,
                opacity: 0,
                onComplete: function () {
                    resetWebflow(data);
                    done();
                }
            });

        },
        after: function(data) {
            
            var el = data.next.container.querySelector('.main-wrapper');

            var done = this.async();

            let enter_tl = gsap.timeline({
               onComplete: function() {
                    document.body.classList.remove('inTransition');
                    document.body.classList.remove('inTransition-isOut');
                    gsap.set(el, { clearProps: "all" });
                    done();                         
                }
            });

            enter_tl
            .to(el, { duration: 1, opacity: 1, y: 0, ease: "power4.out" });
        }

    },
    {
        name: 'projects',
        from: { namespace: ['home'] },
        to: { namespace: ['project']},
        beforeEnter(data) {
            var done = this.async();

            gsap.set(data.next.container, {
                top: 0,
                width: '100%',
                position: 'fixed',
                opacity: 0,
            });

            lastScrollTop = window.scrollY;

            const listCover = data.trigger.querySelector('img');
            listCover.classList.add('readyToTransition');

            setTimeout(() => {
                done();
            }, 1400);

        },
        enter(data) {

            var done = this.async();
            var el = data.current.container.querySelector('.main-wrapper');

            const listCover = data.trigger.querySelector('img');

            const oldW = listCover.clientWidth,
                oldH = listCover.clientHeight,
                oldPos = listCover.getBoundingClientRect();

            const projectCover = data.next.container.querySelector('.project__img');
            const newPos = projectCover.getBoundingClientRect();

            const tempImg = listCover.cloneNode(true);
            document.body.appendChild(tempImg);
            tempImg.classList.add('project-cover-temp');
            

            gsap.set(tempImg, {
                left: `${oldPos.x}px`,
                top: `${oldPos.y}px`,
                width: `${oldW}px`,
                height: `${oldH}px`,
                position: 'fixed',
                zIndex: 9999,
            });
            
            let leave_tl = gsap.timeline({
                onComplete: function () {
                    data.current.container.remove();
                    resetWebflow(data);
                    done();
                }
            });

            leave_tl
            .to(el, { opacity: 0, duration: 1, ease: "power3" })
            .to(tempImg, {
                width: projectCover.clientWidth,
                height: projectCover.clientHeight,
                left: newPos.x,
                top: newPos.y,
                duration: 1, 
                ease: "power3"
            }, 0.4);
        },
        afterEnter(data) {

            var done = this.async();
            
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

            let enter_tl = gsap.timeline({
                onComplete: function () {
                    done();
                }
            });

            enter_tl
            .set('.project__gallery', { autoAlpha: 0 })
            .to(data.next.container, { opacity: 1, position: 'relative', duration: 1, ease: "power3", onComplete: function () {
                document.querySelector('.project-cover-temp').remove();
            }
            })
            .to('.project__gallery', { autoAlpha: 1, y: 0, duration: 1.4 })
            .to('.project__gallery img', { scale: 1.1, duration: 1.4, ease: "power3" }, '-=1.4');
                
        },
    },
       ],
    prevent: ({ el }) => el.classList && el.classList.contains('barbaPrevent') || el.href.includes('/#')
});

*/