import Lenis from '@studio-freight/lenis';

let lenis;

const init = () => {

    let body = document.querySelector('body');
    let topCheckpoint = 200;

    const elScrollTo = document.querySelectorAll('[data-scroll-to');
    if(elScrollTo){
        elScrollTo.forEach((item) => {

            item.addEventListener('click', function(e) {
                e.preventDefault();
                let target = '#'+item.getAttribute('data-scroll-to');
                let scrollToElem = document.querySelector(target);
                item.setAttribute('href', target);
            });
        });
    }

    // Lenis
    lenis = new Lenis();

    //get scroll value
    lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
        //console.log({ scroll, limit, velocity, direction, progress });

        if (scroll > topCheckpoint) {
            body.classList.add('topCheckpoint');
        } else {
            body.classList.remove('topCheckpoint');
        }
    })

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    
    const nextSection = document.querySelectorAll('.goToNextSection');

    if(nextSection){
        nextSection.forEach((item) => {

            item.addEventListener('click', function(e) {
                e.preventDefault();
                let nextSectionID = item.parentNode.offsetParent.nextElementSibling.getAttribute('id');
                item.setAttribute('href', '#'+nextSectionID);
                lenis.scrollTo(item.parentNode.offsetParent.nextElementSibling, {immediate: true});
            });
        });
    }
};

const stopLenis = () => {
    lenis.stop();
}

const startLenis = () => {
    lenis.start();
}

const scrollTo = (elem, offset = 0) => {
    lenis.scrollTo(elem, {immediate: true, offset: offset});
}

export default {init, scrollTo, stopLenis, startLenis};